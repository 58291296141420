<template>
    <div>
        <div class="content-main">

            <div class="article-nnav">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
                    <el-breadcrumb-item>卡组列表</el-breadcrumb-item>
                    <el-breadcrumb-item v-if="info.type > 1">{{info.site_deck_content.nav_title}}</el-breadcrumb-item>
                    <el-breadcrumb-item v-if="info.type == 1">{{info.tournament.name}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <!--头部内容-->
            <div class="content-header">
                <h1 v-if="info.type > 1">{{info.site_deck_content.title}}</h1>
                <h1 v-if="info.type == 1">{{info.tournament.name+"&nbsp;"+"第"+info.rank+"名"}}</h1>
            </div>
            <div>
                <el-divider></el-divider>
            </div>
            <!--top区域内容-->
            <div class="top-area" v-if="info.type > 1" v-html="info.site_deck_content.top_area">
            </div>

            <div class="m-center">
                <h4>卡组详情</h4>
                <el-divider></el-divider>
            </div>
            <div class="deck-info">
                <div class="deck-info-item" v-if="info.type <= 1">
                    <div class="deck-title">[卡组ID]</div>
                    <div class="deck-val">{{info.deck_id}}</div>
                </div>
                <div class="deck-info-item" v-if="info.type <= 1">
                    <div class="deck-title">[赛事类别]</div>
                    <div class="deck-val">{{$webConfig.tournamentType[info.tournament.type].name}}</div>
                </div>
                <div class="deck-info-item" v-if="info.type <= 1">
                    <div class="deck-title">[集团/国家]</div>
                    <div class="deck-val" v-if="info.deck.label==''">无</div>
                    <div class="deck-val" v-if="info.deck.label!=''">{{info.deck.label}}</div>
                </div>
                <!--
                <div class="deck-info-item">
                    <div class="deck-title">[分类]</div>
                    <div class="deck-val">VG高阶</div>
                </div>
                <div class="deck-info-item">
                    <div class="deck-title">[禁卡表]</div>
                    <div class="deck-val">2022523</div>
                </div>-->
                <div class="deck-info-item" v-for="cards in cardsGrounp" :key="cards">
                    <div class="deck-title" v-if="cards.cards.length > 0">[{{cards.name}}]</div>
                    <div class="deck-img">
                        <div
                                class="deck-img-item"
                                v-for="item in cards.cards"
                                :key="item"
                        >
                            <div class="d-img">
                                <img :src="item.card_version.image_url" @click="$router.push({path:'/index/cardDetail',query:{id:item.card_id}})">
                            </div>
                            <div class="d-count">
                                x{{item.count}}
                            </div>
                        </div>
                        <div class="m-clear-both"></div>
                    </div>
                </div>
            </div>

            <!--关键卡牌介绍-->
            <div v-if="info.type > 1 && info.site_deck_content.card_detail_show > 0">
                <div class="m-title video-tt">关键卡牌介绍</div>
                <div class="pk-card" v-for="item in info.site_deck_content.card_detail" :key="item">
                    <div class="pk-card-img">
                        <el-image :src="item.img" style="height: 200px" fit="contain"></el-image>
                    </div>
                    <div class="pk-card-content" v-html="item.text">
                    </div>
                    <div class="m-clear-both"></div>
                </div>
                <div class="m-clear-both"></div>
            </div>
            <!--视频-->
            <div class="m-center video-area" v-if="info.type > 1 && info.site_deck_content.video_show > 0">
                <div class="m-title video-tt">{{info.site_deck_content.video.title}}</div>
                <div class="video-bg" v-html="info.site_deck_content.video.video"></div>
            </div>

            <div class="back-product-top m-a-none">
                <router-link to="/index/deck">
                    <i class="el-icon-caret-right"></i>返回卡组列表
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail",
        data() {
            return {
                info: {},
            }
        },
        methods: {
            getInfo() {
                let deck_id = this.$route.query.deck_id;
                this.$axios.post("site/siteDeck/indexInfo",{deck_id})
                    .then((res)=>{
                        this.info = res.data.data;
                        console.log(res.data.data)
                })
            },
        },
        computed: {
            cardsGrounp() {
                let cards = {
                    main:{
                        name:"主卡组",
                        cards:[]
                    },
                    ride:{
                        name:"ride卡组",
                        cards:[]
                    },
                    other:{
                        name:"其他卡组",
                        cards:[]
                    }
                };
                let deckCards = this.info.deck.deck_cards;
                deckCards.forEach((v)=>{
                    //'main','extra','side','ride','partner'
                    if (v.type == "main"){
                        cards.main.cards.push(v)
                    } else if (v.type == "ride"){
                        cards.ride.cards.push(v)
                    } else if (v.type == "ride"){
                        cards.other.cards.push(v)
                    }
                })
                return cards;
            }
        },
        created() {
            this.getInfo();
        }
    }
</script>

<style scoped>
    .content-main{
        width: 100%;
        margin: 0 auto;
        background-color: white;
        padding: 20px 0;
    }
    .content-header{
        text-align: center;
    }
    .deck-info{
        width: 60%;
        margin: 0 auto;
        text-align: center;
    }
    .deck-title{
        text-align: center;
        height: 32px;
        line-height: 32px;
        font-weight: bold;
        color: white;
        background-color: #dd1b3e;
        width: 60%;
        margin: 0 auto;
        letter-spacing: 5px;
    }
    .deck-val{
        padding-top: 20px;
        font-weight: bold;
    }
    .deck-info-item{
        margin-bottom: 20px;
        font-size: 16px;
    }
    .deck-img{
        padding-top: 25px;
    }

    .deck-img-item{
        width: 17%;
        margin: 1%;
        height: auto;
        display: inline-block;
        position: relative;
    }
    .d-img{
        width: 100%;
    }
    .d-img >img{
        width: 100%;
    }
    .d-count{
        position: absolute;
        background-color: black;
        height: 25px;
        line-height: 25px;
        width: 100%;
        bottom: 0;
        opacity: 0.80;
        color: white;
        border: white;
        letter-spacing: 5px;
    }
    .back-product-top{
        height: 100px;
    }
    .back-product-top > a{
        display: block;
        height: 66px;
        border: 1px solid black;
        width: 274px;
        margin: 40px auto 20px auto;
        line-height: 66px;
        font-size: 26px;
        font-weight: bold;
    }
    .article-nnav{
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }
    .video-bg{
        background-image: url("../../../assets/img/backimg-black.jpg");
        height: 320px;
        padding-top: 2px;
    }
    .video-tt{
        margin: 0 auto;
        width: 50%;
        margin-bottom: 10px;
    }
    .pk-card{
        width: 760px;
        margin: 0 auto;
        text-align: center;
    }
    .pk-card-img{
        float: left;
        width: 30%;
    }
    .pk-card-content{
        float: left;
        text-align: left;
        width: 66%;
        margin-left: 2%;
    }
</style>

<style>
    .video-bg iframe{
        height: 318px;
        width: 480px;
    }
</style>

